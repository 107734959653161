
import AppMixin from '~/mixins/App'
import envMixin from "@/mixins/envMixin";

export default {
  name: 'DefaultIndex',
  mixins: [AppMixin, envMixin],

  computed: {
    showMp4() {
      return this.$device.isIos || this.$device.isSafari
    }
  },

  methods: {
    goTo(url) {
      if (!url) {
        return
      }

      window.open(url, '_blank')
    }
  }
}
