// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../static/fonts/ProximaNovaA-Medium.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Proxima\";src:local(\"Proxima\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}.container-body{font-family:\"Proxima\";padding:0}@media screen and (max-width:575px){.container-body{max-width:100%;padding:0 16px}}@media screen and (min-width:576px){.container-body{margin-left:auto;margin-right:auto;max-width:540px}}@media screen and (min-width:768px){.container-body{margin-left:auto;margin-right:auto;max-width:735px}}@media screen and (min-width:1024px){.container-body{margin-left:auto;margin-right:auto;max-width:992px}}@media screen and (min-width:1200px){.container-body{margin-left:auto;margin-right:auto;max-width:1140px}}.bg-purple{background-color:#6e44ff}.text-purple{color:#6e44ff}.font-32{font-size:32px;line-height:38px}.font-40{font-size:40px;line-height:48px}.font-56{font-size:56px;line-height:64px}.font-64{font-size:64px;line-height:72px}.top-0{top:0!important}.right-0{right:0!important}.left-0{left:0!important}.hidden{overflow:hidden}.banner video{border-radius:32px;color:red;margin:0 auto;max-height:700px}.left-banner{-webkit-backdrop-filter:blur(14px);backdrop-filter:blur(14px);background:linear-gradient(180deg,hsla(0,0%,100%,.3) -45.32%,hsla(0,0%,100%,0) 81.06%,hsla(0,0%,100%,.3) 152.88%);border-radius:16px}@media screen and (min-width:1200px){.banner-container{margin-left:auto;margin-right:auto;max-width:1300px!important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
